import { Api } from '../../Api/Api';
import { AppDispatch, RootState } from '../Store';
import { IUpdateMultipleCrop } from '../../Models/Requests/SourceUpdateTypes';
import { getFarms } from './FarmThunks';
import { createTracedAsyncThunk } from '../../../tracing/trace';

export interface IUpdateFieldCropRequest
{
	BulkUpdateCrop: IUpdateMultipleCrop[];
	FieldYear: string;
	RequestedGrowerId: string;
}

export interface IBulkUpdateCropResponse
{
	NumberOfSuccesses: number;
	Errors: string[];
}

export const updateCropForFields = createTracedAsyncThunk<IBulkUpdateCropResponse, IUpdateFieldCropRequest, { dispatch: AppDispatch, state: RootState }>(
	'update/cropforfields',
	async (context, request: IUpdateFieldCropRequest, thunkAPI) => 
	{
		try
		{
			const api = new Api('/api/6', thunkAPI.getState().auth.userAuthToken, context);
			const { BulkUpdateCrop, FieldYear, RequestedGrowerId } = request;
			
			const response = await api.postAsync<IBulkUpdateCropResponse>(`years/${FieldYear}/crop`, BulkUpdateCrop);

			if (response.ErrorCode === null && response.Success)
			{
				// Re-pull the farm field tree
				thunkAPI.dispatch(getFarms({ growerId: RequestedGrowerId, successMessage: 'Successfully updated crops across fields.' }));

				// 10/18/2022 - this function actually returns a IBulkUpdateCropResponse but we do not currently care about the return data, only that it was a success
				return response.Data;
			}
			else 
			{
				return thunkAPI.rejectWithValue(response.ErrorMessage);
			}
		}
		// Likely a NetError thrown from the Api class
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);