import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FeatureCollection, Polygon } from '@turf/turf';
import { SortType } from '../../../components/Table/TableHeader';
import { IGrowerResponse } from '../../Models/Responses/GrowerResponse';
import { ICustomTreatmentResponse } from '../../Models/Responses/CustomTreatmentResponse';
import { IPlanResponse } from '../../Models/Responses/PlanResponse';
import { RootState } from '../Store';
import { IPlantingPlanEquipmentResponse } from '../../Models/Responses/PlantingPlanEquipmentResponse';
import { getSupportedEquipment } from '../Grower/PlantingPlanThunks';
import { selectGrowerAndPullSeeds } from './UIThunks';
import { getGrowers } from '../Grower/GrowerThunks';
import { TabType } from '../../../pages/Dashboard/Dashboard';
import { downloadPpgPdf } from '../PPG/PpgThunks';

export type BoolDictionary = { [id: string]: boolean };

export enum ThemeName { GHX, Enogen }

// The different supported ways of interacting with a the map
export enum MapInteractionMode
{
	Selection,
	Drawing,
	CLU,
}

export enum DrawingInteractionMode
{
	Nothing,
	SaveCleanUp
}

export interface IMapInteractionMode
{
	mode: MapInteractionMode.Selection | MapInteractionMode.CLU;
}

export interface IDrawingInterfaceMode
{
	mode: MapInteractionMode.Drawing;
	drawingMode: DrawingInteractionMode;
}

export type UISort<T> = { sortDirection: SortType; field: keyof T; };

export type IInteractionMode = IMapInteractionMode | IDrawingInterfaceMode;

export interface IUIState
{
	AgriclimeFlowDisplayed: string; // the URL of the Agriclime flow to display, undefined if hidden
	// Key is the Brand name, this list populates while the user is logged in, if they log out, this dictionary will clear itself.
	CompetitorBrandsAndProducts: {[key: string]: string[]};
	CurrentTheme: ThemeName;
	DashboardSort: UISort<IGrowerResponse>;
	DashboardTableExpanded: BoolDictionary;
	DrawnFeatures: FeatureCollection<Polygon>;
	EnogenFlowDisplayed: string; // the URL of the Enogen flow to display, undefined if hidden
	errorMessage: string;
	isError: boolean;
	isLoading: boolean;
	MapInteractionMode: IInteractionMode;
	PlantingPlanEquipment: IPlantingPlanEquipmentResponse;
	RecallSort: UISort<IPlanResponse>;
	SelectedDashboardTab: TabType;
	SelectedEnogenContractId: string;
	SelectedFieldDictionary: BoolDictionary;
	SelectedFieldForEdit: string;
	SelectedFields: string[];
	SelectedGrowerId: string;
	SelectedPlanId: string;
	SelectedYear: string;
	ShowDrawingTools: boolean;
	ShowMapSearch: boolean;
	TreatmentSort: UISort<ICustomTreatmentResponse>;
	TreatmentsTableExpanded: BoolDictionary;
	InitialUserFoundationId?: string;
	InitialGrowerFoundationId?: string;
	InitialSalesforceOrderId?: string;

	PostLoginProgress: {
		loginType: 'password' | 'sso' | 'exchange_token';
		landingPage: string;
		status: 'none' | 'start' | 'fetching_users' | 'fetching_growers' | 'done' | 'error';
		/** When status is 'error' this will have the description of that error. */
		fatalError?: string;
		/** Non fatal errors that may occur during one of the non-critical loading statuses. */
		errors: ('initial_user_not_found' | 'initial_grower_not_found')[];
	};
}

export const initialState: IUIState = {
	AgriclimeFlowDisplayed: undefined,
	CompetitorBrandsAndProducts: {},
	CurrentTheme: ThemeName.GHX,
	DashboardSort: { sortDirection: 'ascending', field: 'Name' },
	DashboardTableExpanded: {},
	DrawnFeatures: undefined,
	EnogenFlowDisplayed: undefined,
	errorMessage: undefined,
	isError: false,
	isLoading: false,
	MapInteractionMode: { mode: MapInteractionMode.Selection },
	PlantingPlanEquipment: undefined,
	RecallSort: { sortDirection: 'descending', field: 'Modified' },
	SelectedDashboardTab: 'Plan',
	SelectedEnogenContractId: undefined,
	SelectedFieldDictionary: {},
	SelectedFields: [],
	SelectedFieldForEdit: undefined,
	SelectedGrowerId: undefined,
	SelectedPlanId: undefined,
	SelectedYear: undefined,
	ShowDrawingTools: false,
	ShowMapSearch: false,
	TreatmentSort: { sortDirection: 'descending', field: 'Created' },
	TreatmentsTableExpanded: {},
	InitialUserFoundationId: undefined,
	InitialGrowerFoundationId: undefined,
	InitialSalesforceOrderId: undefined,
	PostLoginProgress: {
		landingPage: undefined,
		status: 'none',
		errors: [],
		loginType: 'password',
	},
};

export const uiSlice = createSlice({
	name: 'ui',
	initialState,
	reducers: {
		setPostLoginProgress: (state, { payload }: PayloadAction<IUIState['PostLoginProgress']>) => 
		{
			state.PostLoginProgress = payload;
			return state;
		},
		clearPostLoginProgressError: (state) => 
		{
			state.PostLoginProgress.errors = [];
			state.PostLoginProgress.fatalError = undefined;
			return state;
		},
		resetPostLoginProgress: (
			state, 
			{ payload }: PayloadAction<{ loginType?: IUIState['PostLoginProgress']['loginType']}>
		) =>
		{
			state.PostLoginProgress = {
				...initialState.PostLoginProgress,
				...(payload.loginType && { loginType: payload.loginType })
			};
			return state;
		},
		setInitialUserFoundationId: (state, { payload }: PayloadAction<string>) => 
		{
			state.InitialUserFoundationId = payload;
		},
		setInitialGrowerFoundationId: (state, { payload }: PayloadAction<string>) =>
		{
			state.InitialGrowerFoundationId = payload;
		},
		setInitialSalesforceOrderId: (state, {payload }: PayloadAction<string>) =>
		{
			state.InitialSalesforceOrderId = payload;
		},
		clearExpandedState: (state) => 
		{
			state.DashboardTableExpanded = {};
			return state;
		},
		clearSelectedEnogenContractId: (state) =>
		{
			state.SelectedEnogenContractId = undefined;
			return state;
		},
		clearSelectedGrowerIdState: (state) =>
		{
			state.SelectedGrowerId = undefined;
			return state;
		},
		clearSelectedPlanIdState: (state) =>
		{
			state.SelectedPlanId = undefined;
			return state;
		},
		clearSelectedYearState: (state) =>
		{
			state.SelectedYear = undefined;
			return state;
		},
		setDashboardExpanded: (state, { payload }: PayloadAction<BoolDictionary>) =>
		{
			state.DashboardTableExpanded = {
				...state.DashboardTableExpanded,
				...payload
			};

			return state;
		},
		setTreatmentsExpanded: (state, { payload }: PayloadAction<BoolDictionary>) =>
		{
			state.TreatmentsTableExpanded = {
				...state.TreatmentsTableExpanded,
				...payload
			};

			return state;
		},
		setSelectedGrowerId: (state, { payload }: PayloadAction<string>) =>
		{
			state.SelectedGrowerId = payload;
			// Clear any selected fields
			state.SelectedFields = [];
			state.SelectedFieldDictionary = {};

			return state;
		},
		setSelectedPlanId: (state, { payload }: PayloadAction<string>) =>
		{
			state.SelectedPlanId = payload;
			return state;
		},
		setSelectedYear: (state, { payload }: PayloadAction<string>) =>
		{
			state.SelectedYear = payload;
			return state;
		},
		hideEnogenFlow: (state) => 
		{
			state.EnogenFlowDisplayed = undefined;
			return state;
		},
		hideAgriclimeFlow: (state) =>
		{
			state.AgriclimeFlowDisplayed = undefined;
			return state;
		},
		// Likely from selecting a field from the map
		addSingleSelectedField: (state, { payload }: PayloadAction<string>) =>
		{
			// Remove it if it already exists, this is probably a second click that would 'un-select' the field
			if (state.SelectedFields.find(sf => sf === payload))
			{
				state.SelectedFields = state.SelectedFields.filter(sf => sf !== payload);
				state.SelectedFieldDictionary = {
					...state.SelectedFieldDictionary,
					[payload]: false,
				};
			}
			else
			{
				state.SelectedFields.push(payload);
				state.SelectedFieldDictionary = {
					...state.SelectedFieldDictionary,
					[payload]: true,
				};
			}

			return state;
		},
		// When toggling from a checkbox
		setFieldIsSelected: (state, { payload }: PayloadAction<BoolDictionary>) =>
		{
			state.SelectedFieldDictionary = {
				...state.SelectedFieldDictionary,
				...payload
			};

			state.SelectedFields = Object.keys(state.SelectedFieldDictionary)
				.filter(key => state.SelectedFieldDictionary[key]);

			return state;
		},
		clearSelectedFields: (state) =>
		{
			state.SelectedFields = [];
			state.SelectedFieldDictionary = {};
			return state;
		},
		displayEnogenFlow: (state, { payload }: PayloadAction<{
			accessToken: string;
			selectedGrowerId: string;
			apiBaseUrl: string;
			enogenFlowBaseUrl: string;
			userId: string;
			contractId?: string;
			viewDetails?: boolean;
			assignFields?: boolean;
		}>) =>
		{
			// eg: https://map-centric-alpha.azurewebsites.net/enogen/90b381b6-a4f3-409b-bad0-088bf588ac0e/eyJhbG[…]_ZWU-SIyy4mmR_7aK0ZuPXjAcF2Za_SGtCsrtFNnVV_2E2-kz4g
			const contractIdQueryString = payload.contractId ? `&contract_id=${payload.contractId}` : '';
			const viewDetailsQueryString = payload.viewDetails ? '&view_details=true' : '';
			const assignFieldsQueryString = payload.assignFields ? '&assign_fields=true' : '';
			state.EnogenFlowDisplayed = `${payload.enogenFlowBaseUrl}` +
			`/enogen/${payload.selectedGrowerId}/${payload.accessToken}?apiBaseUrl=${payload.apiBaseUrl}` +
			`&userId=${payload.userId}${contractIdQueryString}${viewDetailsQueryString}${assignFieldsQueryString}`;
			return state;
		},
		displayAgcriclimeFlow: (state, { payload }: PayloadAction<{
			baseUrl: string;
			growerId: string;
			userId: string;
			accessToken: string;
		}>) =>
		{
			const { baseUrl, growerId, userId, accessToken } = payload;
			// e.g.: https://nk-css-dev.apps.dev.use1.devops.syngentadigitalapps.com/agriclime-eluminate?growerId=faf94b78-c572-47a8-bfd0-42e51ab8e9d3&userId=cef02692-8153-4d31-a9d3-e35633bf6b8c&token=[…]_0KDfFbWUq3OpGVdZA
			state.AgriclimeFlowDisplayed = `${baseUrl}?growerId=${growerId}&userId=${userId}&token=${accessToken}`;
		},
		toggleDrawingTools: (state, { payload }: PayloadAction<boolean>) =>
		{
			state.ShowDrawingTools = payload;
			return state;
		},
		setMapInteractionMode: (state, { payload }: PayloadAction<IInteractionMode>) =>
		{
			state.MapInteractionMode = payload;
			return state;
		},
		setDrawnFeatures: (state, { payload }: PayloadAction<FeatureCollection<Polygon>>) =>
		{
			state.DrawnFeatures = payload;
			return state;
		},
		deleteDrawnFeatures: (state) =>
		{
			state.DrawnFeatures = undefined;
			return state;
		},
		setDashboardSort: (state, { payload }: PayloadAction<UISort<IGrowerResponse>>) =>
		{
			state.DashboardSort = payload;
			return state;
		},
		setTreatmentSort: (state, { payload }: PayloadAction<UISort<ICustomTreatmentResponse>>) =>
		{
			state.TreatmentSort = payload;
			return state;
		},
		setRecallSort: (state, { payload }: PayloadAction<UISort<IPlanResponse>>) =>
		{
			state.RecallSort = payload;
			return state;
		},
		setShowMapSearch: (state, { payload }: PayloadAction<boolean>) =>
		{
			state.ShowMapSearch = payload;
			return state;
		},
		clearError: (state) =>
		{
			state.isError = false;
			state.errorMessage = undefined;
			return state;
		},
		setSelectedFieldForEdit: (state, { payload }: PayloadAction<string>) =>
		{
			state.SelectedFieldForEdit = payload;
			return state;
		},
		clearSelectedFieldForEdit: (state) =>
		{
			state.SelectedFieldForEdit = undefined;
			return state;
		},
		addCompetitorBrand: (state, { payload }: PayloadAction<string>) =>
		{
			if (!Object.keys(state.CompetitorBrandsAndProducts).map(k => k.toLocaleLowerCase()).includes(payload.toLocaleLowerCase()))
			{
				state.CompetitorBrandsAndProducts[payload] = [];
			}

			return state;
		},
		addProductToCompetitorBrand: (state, { payload }: PayloadAction<{brand: string, product: string}>) =>
		{
			if (!Object.keys(state.CompetitorBrandsAndProducts).map(k => k.toLocaleLowerCase()).includes(payload.brand.toLocaleLowerCase()))
			{
				state.CompetitorBrandsAndProducts[payload.brand] = [payload.product];
			}
			else
			{
				if (!state.CompetitorBrandsAndProducts[payload.brand].map(p => p.toLocaleLowerCase()).includes(payload.product.toLocaleLowerCase()))
				{
					state.CompetitorBrandsAndProducts[payload.brand].push(payload.product);
				}
			}

			return state;
		},
		setCurrentTheme: (state, { payload }: PayloadAction<ThemeName>) =>
		{
			state.CurrentTheme = payload;
			return state;
		},
		setSelectedEnogenContractId: (state, { payload }: PayloadAction<string>) =>
		{
			state.SelectedEnogenContractId = payload;
			return state;
		},
		setSelectedDashboardTab: (state, { payload }: PayloadAction<TabType>) =>
		{
			state.SelectedDashboardTab = payload;
			return state;
		},
		clearSelectedDashboardTab: (state) =>
		{
			state.SelectedDashboardTab = 'Plan';
			return state;
		},
	},
	extraReducers: (builder) =>
	{
		builder.addCase(getSupportedEquipment.pending, (state) =>
		{
			// Only show the loading spinner if we don't already have this data
			if(!state.PlantingPlanEquipment?.monitors.length)
			{
				state.isLoading = true;
			}
			state.isError = false;
			state.errorMessage = undefined;
		});
		builder.addCase(getSupportedEquipment.fulfilled, (state, { payload }: PayloadAction<IPlantingPlanEquipmentResponse>) =>
		{
			state.PlantingPlanEquipment = payload;
			state.isLoading = false;
			state.isError = false;
			state.errorMessage = undefined;
		});
		builder.addCase(getSupportedEquipment.rejected, (state, action) =>
		{
			state.isLoading = false;
			state.isError = true;
			if (action.payload)
			{
				if ((action.payload as string).indexOf('network') > -1)
				{
					state.errorMessage = 'There was a problem contacting the server to get the list of Planting Plan Equipment. Please refresh and try again.';
				}
				else
				{
					state.errorMessage = 'There was a problem getting the list of Planting Plan Equipment. Please refresh and try again.';
				}
			}
			else
			{
				state.errorMessage = 'There was a problem getting the list of Planting Plan Equipment. Please refresh and try again.';
			}
		});

		/**
		 * Select the Grower and Pull the Seeds at the same time
		 */
		builder.addCase(selectGrowerAndPullSeeds.pending, (state) =>
		{
			state.isError = false;
			state.errorMessage = undefined;
		});
		builder.addCase(selectGrowerAndPullSeeds.fulfilled, (state) =>
		{
			state.isError = false;
			state.errorMessage = undefined;
		});
		builder.addCase(selectGrowerAndPullSeeds.rejected, (state) =>
		{
			state.isError = false;
			state.errorMessage = undefined;
		});

		/**
		 * PPG download
		 */
		builder.addCase(downloadPpgPdf.pending, (state) =>
		{
			state.isLoading = true;
			state.isError = false;
			state.errorMessage = undefined;
		});
		builder.addCase(downloadPpgPdf.fulfilled, (state, { payload }: PayloadAction<Blob>) =>
		{
			state.isLoading = false;
			state.isError = false;
			state.errorMessage = undefined;
		});
		builder.addCase(downloadPpgPdf.rejected, (state, action) =>
		{
			state.isLoading = false;
			state.isError = true;
			if (action.payload)
			{
				if ((action.payload as string).indexOf('network') > -1)
				{
					state.errorMessage = 'There was a problem contacting the server to get the Product Placement Guide PDF. Please refresh and try again.';
				}
				else
				{
					state.errorMessage = 'There was a problem getting the Product Placement Guide PDF. Please refresh and try again.';
				}
			}
			else
			{
				state.errorMessage = 'There was a problem getting the Product Placement Guide PDF. Please refresh and try again.';
			}
		});
	},
});

export const {
	addCompetitorBrand,
	addProductToCompetitorBrand,
	addSingleSelectedField,
	clearError,
	clearExpandedState,
	clearSelectedEnogenContractId,
	clearSelectedFieldForEdit,
	clearSelectedFields,
	clearSelectedGrowerIdState,
	clearSelectedPlanIdState,
	clearSelectedYearState,
	deleteDrawnFeatures,
	displayAgcriclimeFlow,
	displayEnogenFlow,
	hideAgriclimeFlow,
	hideEnogenFlow,
	setCurrentTheme,
	setDashboardExpanded,
	setDashboardSort,
	setDrawnFeatures,
	setFieldIsSelected,
	setMapInteractionMode,
	setRecallSort,
	setSelectedFieldForEdit,
	setSelectedGrowerId,
	setSelectedPlanId,
	setSelectedYear,
	setShowMapSearch,
	setTreatmentsExpanded,
	setTreatmentSort,
	toggleDrawingTools,
	setInitialUserFoundationId,
	setInitialGrowerFoundationId,
	setInitialSalesforceOrderId,
	setPostLoginProgress,
	clearPostLoginProgressError,
	setSelectedEnogenContractId,
	resetPostLoginProgress,
	clearSelectedDashboardTab,
	setSelectedDashboardTab,
} = uiSlice.actions;

// return who the currently selected grower is
export const selectedGrowerIdSelector = (state: RootState) => state.ui.SelectedGrowerId;
export const currentEnogenFlowUrl = (state: RootState) => state.ui.EnogenFlowDisplayed;
export const currentAgriclimeFlowUrl = (state: RootState) => state.ui.AgriclimeFlowDisplayed;