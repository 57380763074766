import React, { useEffect, useCallback } from 'react';
import { FieldListActionDrawerButton } from './FieldListActionDrawerButton';
import styled, { useTheme } from 'styled-components';
import { IFieldResponse } from '../../logic/Models/Responses/FieldResponse';
import { IGrowerResponse } from '../../logic/Models/Responses/GrowerResponse';
import { connect, ConnectedProps } from 'react-redux';
import { AppDispatch, RootState } from '../../logic/store/Store';
import { launchFieldGamePlan } from '../../logic/store/Grower/PlanThunks';
import { IPlanLaunchRequest } from '../../logic/Models/Requests/PlanRequest';
import { clearGamePlanLaunchUrl } from '../../logic/store/Grower/GrowerSlice';
import { makeDispatch } from '../../logic/Utility/Utils';
import { authSelector, getCurrentActingUser } from '../../logic/store/User/AuthSlice';
import { IFrameModal } from '../../components/IFrameModal/IFrameModal';
import { displayEnogenFlow, hideEnogenFlow, currentEnogenFlowUrl,
	displayAgcriclimeFlow, hideAgriclimeFlow, currentAgriclimeFlowUrl } from '../../logic/store/UI/UISlice';
import { useHistory } from 'react-router-dom';

const FieldListActionDrawerContainer = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;
`;

interface IFieldListActionDrawerProps
{
	selectedGrower: IGrowerResponse;
	selectedFields: IFieldResponse[];
	visible: boolean;
	display: boolean;
}

const BaseFieldListActionDrawer = (props: IFieldListActionDrawerProps & PropsFromRedux) =>
{
	const {
		selectedGrower,
		selectedFields,
		visible,
		display,
		isLoadingPlanLaunch,
		authentication,
		enogenRootUrl,
		userId,
		clearLaunchUrl,
		displayEnogenFlow,
		hideEnogenFlow,
		EnogenFlowUrl,
		agriclimeBaseUrl,
		displayAgriclimeFlow,
		hideAgriclimeFlow,
		agriclimeFlowUrl
	} = props;

	const theme = useTheme();

	const history = useHistory();

	useEffect(() =>
	{
		if (!isLoadingPlanLaunch && selectedGrower?.GamePlanLaunchUrl)
		{
			// Clean the url so that on a return it will not continually launch the url over and over
			clearLaunchUrl(selectedGrower.Id);

			// And redirect to the launch URL
			window.location.href = selectedGrower.GamePlanLaunchUrl;
		}
	}, [selectedGrower?.GamePlanLaunchUrl]);

	const launchFieldGamePlan = useCallback(() =>
	{
		history.push('/fieldactivities/fieldplan');
	}, [history]);

	const displayEnogenFlowOnClick = useCallback(() =>
	{
		displayEnogenFlow({
			accessToken: authentication,
			apiBaseUrl: document.location.host,
			enogenFlowBaseUrl: enogenRootUrl,
			selectedGrowerId: selectedGrower.Id,
			userId
		});
	}, [authentication, document.location.host, selectedGrower, userId]);

	const displayAgriclimeFlowOnClick = useCallback(() =>
	{
		displayAgriclimeFlow({
			baseUrl: agriclimeBaseUrl,
			growerId: selectedGrower.Id,
			userId,
			accessToken: authentication
		});
	}, [selectedGrower, userId, authentication]);

	return (
		<FieldListActionDrawerContainer
			className='fieldListActionDrawer'
			style={{
				width: visible ? 175 : 0,
				padding: visible ? '57px 20px' : 0,
				visibility: visible ? 'visible' : 'hidden',
				display: display ? 'flex' : 'none',
				borderLeft: visible ? `1px solid ${theme.colors.lightestGrey}` : 'none'
			}}
		>
			<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', fontSize: 12, marginBottom: 35 }}>
				<div style={{ fontWeight: 700 }}>
					{`${selectedFields.length} field${selectedFields.length > 1 ? 's' : ''} selected`}
				</div>
				<div>
					{`${selectedFields.map(field => field.Area).reduce((a1, a2) => (a1 ?? 0) + (a2 ?? 0), 0).toFixed(1)} acres`}
				</div>
			</div>
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				<FieldListActionDrawerButton
					variant='gameplan'
					onClick={launchFieldGamePlan}
					className='runGameplanActionButton'
				/>
				{/* <FieldListActionDrawerButton
					variant='agriclime'
					onClick={displayAgriclimeFlowOnClick}
					className='agriclimeActionButton'
				/> */}
				<FieldListActionDrawerButton
					variant='enogen'
					onClick={displayEnogenFlowOnClick}
					className='enogenContractActionButton'
				/>
			</div>
			<IFrameModal title="Enogen Contracting" width={1200} open={!!EnogenFlowUrl}
				onCancel={() => hideEnogenFlow()}
				footer={null}>
				<iframe src={EnogenFlowUrl} />
			</IFrameModal>
			<IFrameModal title="AgriClime" width={1200} open={!!agriclimeFlowUrl}
				onCancel={() => hideAgriclimeFlow()}
				footer={null}>
				<iframe src={agriclimeFlowUrl} />
			</IFrameModal>
		</FieldListActionDrawerContainer>
	);
};

const mapStateToProps = (state: RootState) => ({
	isLoadingPlanLaunch: state.grower.isLoadingPlanLaunch,
	isError: state.grower.isError,
	errorMessage: state.grower.errorMessage,
	allGrowers: state.grower.Growers,
	authentication: authSelector(state)?.userAuthToken,
	enogenRootUrl: state.config.MapCentricUrl,
	EnogenFlowUrl: currentEnogenFlowUrl(state),
	agriclimeBaseUrl: state.config.AgriclimeUrl,
	agriclimeFlowUrl: currentAgriclimeFlowUrl(state),
	userId: getCurrentActingUser(state).UserId,
	recording: state.recording,
});

const mapDispatchToProps = (dispatch: AppDispatch) =>
{
	return {
		clearLaunchUrl: (growerId: string) => dispatch(clearGamePlanLaunchUrl(growerId)),
		displayEnogenFlow: makeDispatch(dispatch, displayEnogenFlow),
		displayAgriclimeFlow: makeDispatch(dispatch, displayAgcriclimeFlow),
		hideEnogenFlow: makeDispatch(dispatch, hideEnogenFlow),
		hideAgriclimeFlow: makeDispatch(dispatch, hideAgriclimeFlow),
	};
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export const FieldListActionDrawer = connector(BaseFieldListActionDrawer);