import React, { useEffect, useState, useCallback } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { ReactComponent as CoreLogo } from '../../assets/images/GH-GHX-Logos-Orange.svg';
import { ReactComponent as EnogenCoreLogo } from '../../assets/images/EluminateEnogenLogos.svg';
import { Dropdown, Tooltip, Modal, Badge } from 'antd';
import { TabButton } from '../../components/TabButton/TabButton';
import { IconButton } from '../../components/IconButton/IconButton';
import { StyledModal } from '../../components/StyledModal/StyledModal';
import { UserSettingsContent } from './UserSettings/UserSettingsContent';
import { SoybeanTreatments } from './UserTreatments/SoybeanTreatments';
import { hasEffectivePermission, isManagingAccount, getCurrentActingUser } from '../../logic/store/User/AuthSlice';
import * as queryString from 'query-string';
import
{
	clearSelectedGrowerIdState,
	toggleDrawingTools,
	deleteDrawnFeatures,
	IInteractionMode,
	setMapInteractionMode,
	MapInteractionMode,
	setShowMapSearch,
	clearSelectedDashboardTab,
	clearSelectedYearState,
} from '../../logic/store/UI/UISlice';
import { startLogrocketRecording, clearState } from '../../logic/store/UI/RecordingSlice';
import { getProductAnalyzerLaunchUrl, redirectToGhxCoreSso, redirectToGhxLogisticsSso } from '../../logic/store/User/UserInfoSlice';
import { connect, ConnectedProps } from 'react-redux';
import { AppDispatch, persistor, RootState } from '../../logic/store/Store';
import { UserAttributes } from './UserAttributes/UserAttributes';
import styled, { useTheme } from 'styled-components';
import { UserPermission } from '../../logic/Models/Responses/UserPermissions';
import { makeDispatch, sleepOnIt } from '../../logic/Utility/Utils';
import { DataHubMenu } from './DataHubMenu';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { clearState as clearFileState } from '../../logic/store/MachineFileData/FileSlice';
import { useAmplitudePassthrough, useNavigationEvent } from '../../logic/Utility/useAmplitude';
import { themeGet } from '@styled-system/theme-get';
import { useScopedSession } from '../../tracing/session';
import { EventStructureTags } from '../../tracing/EventTagNames';
import { EventNavigationNames } from '../../tracing/EventNames';
import { featureFlagSelector, Features, getFeatureFlag } from '../../logic/store/User/ConfigSlice';
import { PpgMain } from './Ppg/PpgMain';

export interface IHeaderProps extends PropsFromRedux
{

}

const headerRoutes = {
	'/seeds': 'Seeds',
	'/dashboard': 'Dashboard',
	'/fieldactivities': 'Field Activities',
	'/datahub': 'Data Hub',
};
const pathSplit = /([/]\w+)/g;
const getSelectedPath = (pathname) =>
	pathname.split(pathSplit)
		.filter(part => part.length)
		.find(item => headerRoutes[item]);

const TitleMenuItem = styled.div`
	padding: 0;
	font-weight: ${themeGet('fontWeights.bold')};
	font-size: ${themeGet('fontSizes.normal')};
	line-height: ${themeGet('fontSizes.large')};
	color: ${themeGet('colors.darkGrey')};
`;

const SubTitleMenuItem = styled.div`
	font-weight: ${themeGet('fontWeights.bold')};
	font-size: ${themeGet('fontSizes.extraSmall')};
	line-height: ${themeGet('fontSizes.small')};
	color: ${themeGet('colors.mediumGrey')};
`;

export const HeaderComponent = (props: IHeaderProps) =>
{
	const {
		CanRecord,
		CanSeeTroubleShootingTools,
		CanSeeUserAdmin,
		Growers,
		HasGhxCoreSSO,
		IsLoadingGetTreatments,
		IsLoadingGrowers,
		IsLoadingSaveTreatments,
		IsLoadingUserAttributes,
		IsManagingAccount,
		IsRecording,
		IsSavingUserAttributes,
		SelectedAccount,
		UserInfo,
		UserSeesEnogenTheme,
		FeatureFlags,
		ChangeInteractionMode,
		ClearFileState,
		ClearRecordingState,
		ClearSelectedGrowerId,
		DeleteDrawn,
		DisplayDrawingTools,
		SetShowMapSearch,
		StartRecording,
		LaunchGhxCoreSso,
		ClearSelectedDashboardTab,
		ClearSelectedDashboardYear,
	} = props;

	const theme = useTheme();
	const location = useLocation();
	const history = useHistory();

	const [selectedTab, setSelectedTab] = useState<string>('/dashboard');
	const [areGrowersAvailable, setAreGrowersAvailable] = useState(!IsLoadingGrowers && Growers.length > 0);
	const [settingsModalVisible, setSettingsModalVisible] = useState(false);
	const [attributesModalVisible, setAttributesModalVisible] = useState(false);
	const [customTreatmentModalVisible, setCustomTreatmentModalVisible] = useState(false);
	const [ppgModalVisible, setPpgModalVisible] = useState(false);
	
	const session = useScopedSession(HeaderComponent.name, {
		[EventStructureTags.PageContext]: 'header',
		[EventStructureTags.PageUrl]: window.location.toString()
	});

	const selectTab = useCallback((tabName: string) =>
	{
		if (tabName === '/datahub')
		{
			// Only select the tab for datahub
			setSelectedTab(tabName);
			return;
		}

		ClearSelectedGrowerId();
		ClearFileState();
		setSelectedTab(tabName);
		DisplayDrawingTools(false);
		SetShowMapSearch(false);
		ChangeInteractionMode({ mode: MapInteractionMode.Selection });
		DeleteDrawn();
		ClearSelectedDashboardTab();
		ClearSelectedDashboardYear();
	}, [selectedTab]);

	const performLogout = useAmplitudePassthrough(session, EventNavigationNames.LogOut, () =>
	{
		persistor.purge().then(() => history.go(0));
	}, []);
	
	const dataHubMenu = DataHubMenu({openPpgModal: () => setPpgModalVisible(true), ...props});

	useEffect(() => 
	{
		let path = getSelectedPath(location.pathname);

		// As a fallback, search the current URL for keywords
		if (!path)
		{
			if (location.pathname.includes('dashboard'))
			{
				path = '/dashboard';
			}
			else if (location.pathname.includes('fieldactivities') || location.pathname.includes('productgameplan'))
			{
				path = '/fieldactivities';
			}
		}

		setSelectedTab(path);
	}, [location.pathname]);

	useEffect(() =>
	{
		setAreGrowersAvailable(!IsLoadingGrowers && Growers.length > 0);
	}, [Growers]);

	// Get any relevant parameters from the url
	const parsed = queryString.parse(location.search);

	const trackStartRecording = useAmplitudePassthrough(session, EventNavigationNames.StartRecording, 
		() => StartRecording((parsed.cardid as string) ?? '1234'), [StartRecording, parsed.cardid]);

	
	const trackAccountInfo = useAmplitudePassthrough(session, EventNavigationNames.To, 
		() => setSettingsModalVisible(true), [], {
			[EventStructureTags.DestinationName]: 'account_info'
		});
	const trackCustomTreatments = useAmplitudePassthrough(session, EventNavigationNames.To, 
		() => setCustomTreatmentModalVisible(true), [], {
			[EventStructureTags.DestinationName]: 'custom_treatments'
		});
	const trackDefaultAttributes= useAmplitudePassthrough(session, EventNavigationNames.To, 
		() => setAttributesModalVisible(true), [], {
			[EventStructureTags.DestinationName]: 'default_attributes'
		});
	const trackSupport = useNavigationEvent(session, 'support', 'https://agconnections.zendesk.com/hc/en-us/categories/1500001012341-E-Luminate');
	const trackTraining = useNavigationEvent(session, 'request_training', 'https://app.smartsheet.com/b/form/b235b2efb91c448e8b124307a68bd851');
	const trackHelpfulLinks = useNavigationEvent(session, 'helpful_links', 'https://agconnections.zendesk.com/hc/en-us/categories/1500001012341-E-Luminate');

	const trackDashboard = useAmplitudePassthrough(session, EventNavigationNames.To, 
		() => selectTab('/dashboard'), [], {
			[EventStructureTags.DestinationName]: 'dashboard'
		});
	const trackSeeds= useAmplitudePassthrough(session, EventNavigationNames.To, 
		LaunchGhxCoreSso, [], {
			[EventStructureTags.DestinationName]: 'seeds'
		});
	const trackFieldActivities = useAmplitudePassthrough(session, EventNavigationNames.To, 
		() => selectTab('/fieldactivities'), [], {
			[EventStructureTags.DestinationName]: 'fieldactivities'
		});
	const trackDataHub = useAmplitudePassthrough(session, EventNavigationNames.To, 
		() => selectTab('/datahub'), [], {
			[EventStructureTags.DestinationName]: 'datahub'
		});

	// See if the url has requested a recording
	useEffect(() => 
	{
		// If there's a url request and we haven't already processed it
		if(parsed.record === 'true' && !IsRecording && UserInfo?.UserId)
		{
			// Remove the request from the url so we don't keep recording future sessions
			window.history.pushState({path:window.location.origin},'',window.location.origin);

			// Make sure the user acknowledges and confirms
			Modal.confirm({
				onOk: trackStartRecording,
				title: 'Consent to recording of your session',
				content: <div>
					Syngenta has shared this url that will record your session in order to assist with a support request.<br/>
					If you accept, all activity in this site will be recorded until you leave the site or reload the page.<br/>
					<br/>
					Do you consent to sharing your activity in this session of E-luminate?
				</div>
			});
		}
	}, [parsed.record, IsRecording, trackStartRecording, UserInfo?.UserId]);

	const settingsMenuItems: ItemType[] =
	[
		// If the user is managing an account, include the details here
		(IsManagingAccount && SelectedAccount) && {
			onClick: () => history.push('/dashboard'),
			key: 'Managed Account',
			style: { backgroundColor: theme.colors.secondary },
			label:
			<div>
				<TitleMenuItem>
					{SelectedAccount.UserName?.length > 0 ? SelectedAccount.UserName : SelectedAccount.UserEmail}
				</TitleMenuItem>
				<SubTitleMenuItem>
					{SelectedAccount.FoundationId}
				</SubTitleMenuItem>
			</div>
		},
		// If the user has the permission to record, or is already record (via for example a url) show
		// the record button
		(IsRecording || CanRecord) && {
			onClick: async () => 
			{
				if (IsRecording)
				{
					// Clear the recording state from the data store so it will not try to resume recording later 
					ClearRecordingState();

					// Give the datastore a quick moment to register clearing the recording data or it'll refresh with the previous data
					await sleepOnIt(50); 

					// Refresh the page to forcibly end the logrocket recording 
					window.location.reload();
				}
				else
				{
					StartRecording((parsed.cardid as string) ?? '1234');
				}
			},
			key: 'Record',
			className: 'HeaderProfileRecord',
			label: IsRecording ? <><span style={{color:'red'}}> &#11044; </span> End Recording</> : <>Record Session</>
		},
		{
			onClick: trackAccountInfo,
			key: 'Account Info',
			className: 'HeaderProfileAccountInfo',
			label: 'Account Info'
		},
		{
			onClick: trackCustomTreatments,
			key: 'Soybean Treatments',
			className: 'HeaderProfileCustomTreatments',
			label: 'Soybean Treatments'
		},
		{
			onClick: trackDefaultAttributes,
			key: 'Attribute Preferences',
			className: 'HeaderProfileAttributePreferences',
			label: 'Attribute Preferences'
		},
		{
			key: 'Contact Support',
			label:
			<Tooltip title="Send feedback or report issue" placement="left">
				<a
					onClick={() => trackSupport()}
					href='https://agconnections.zendesk.com/hc/en-us/categories/1500001012341-E-Luminate'
					rel='noreferrer'
					target='_blank'
					className='HeaderProfileContactSupport'>
					Contact Support
				</a>
			</Tooltip>
		},
		{
			key: 'Request Training',
			label:
			<Tooltip title="Send us your information to request a training session" placement="left">
				<a
					onClick={() => trackTraining()}
					href='https://app.smartsheet.com/b/form/b235b2efb91c448e8b124307a68bd851'
					rel='noreferrer'
					target='_blank'
					className='HeaderProfileRequestTraining'>
						Request Training
				</a>
			</Tooltip>
		},
		{
			key: 'Helpful Links',
			label:
			<Tooltip title="Review training videos and articles" placement="left">
				<a
					onClick={() => trackHelpfulLinks()}
					href='https://agconnections.zendesk.com/hc/en-us/categories/1500001012341-E-Luminate'
					rel='noreferrer'
					target='_blank'
					className='HeaderProfileHelpfulLinks'>
						Helpful Links
				</a>
			</Tooltip>
		},
		CanSeeTroubleShootingTools &&
		{
			key: 'TroubleShootingTools',
			className: 'HeaderProfileTroubleShootingTools',
			label: <Link to="/troubleshootingtools" key='TroubleShootingTools_Link'>Troubleshooting Tools</Link>
		},
		(getFeatureFlag(FeatureFlags, Features.InlineUserAdmin) && CanSeeUserAdmin) &&
		{
			key: 'UserAdmin',
			className: 'HeaderProfileTroubleShootingTools',
			label: <Link to="/useradmin" key='User Admin_Link'>User Administration</Link>
		},
		{
			key: 'Logout',
			onClick: performLogout,
			className: 'HeaderProfileLogout',
			label: 'Logout'
		}
	];

	return (
		<>
			<header
				id="header-container"
				style={{ font: 'Roboto', fontWeight: 700, fontSize: 12, height: 60, boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.15)', zIndex: 999 }}>
				<section style={{
					backgroundColor: theme.colors.backgroundLM,
					color: theme.colors.darkGrey,
					display: 'flex',
					height: 60,
					alignItems: 'center',
					paddingLeft: 60,
					paddingRight: 60,
					zIndex: 998
				}}>
					{
						UserSeesEnogenTheme ?
							<EnogenCoreLogo width={'23vw'} height={'5.2vh'} style={{ minWidth: 235 , minHeight: 45 }} />
							:
							<CoreLogo width={'23vw'} height={'5.8vh'} style={{ minWidth: 235 , minHeight: 45 }} />
					}
					<div style={{
						display: 'flex',
						height: '100%',
						flexGrow: 1,
						alignItems: 'flex-end',
						justifyContent: 'flex-end'
					}}>
						<Link to="/dashboard" style={{
							display: 'flex',
							justifyContent: 'center',
							color: theme.colors.darkGrey,
							width: '15%',
							minWidth: '80px',
							maxWidth: '200px',
							height: '100%'
						}}>
							<TabButton
								className='HeaderButtonDashboard'
								width={'100%'}
								style={{ margin: 0, height: '100%' }}
								selected={selectedTab === '/dashboard'}
								onClick={trackDashboard}
							>
								{headerRoutes['/dashboard']}
							</TabButton>
						</Link>
						{ HasGhxCoreSSO && 
							<TabButton
								className='HeaderButtonSeeds'
								width={'15%'}
								style={{ margin: 0, height: '100%', maxWidth: '200px', }}
								selected={false}
								onClick={trackSeeds}
							>
								{headerRoutes['/seeds']}
							</TabButton>
						}
						<Tooltip title={!areGrowersAvailable ? 'Select a Seed Advisor to advance.' : ''}>
							<Link to="/fieldactivities" style={{
								display: 'flex',
								justifyContent: 'center',
								color: theme.colors.darkGrey,
								width: '20%',
								maxWidth: '200px',
								height: '100%'
							}}>
								<TabButton
									className='HeaderButtonFieldActivities'
									width={'100%'}
									style={{ margin: 0, height: '100%' }}
									selected={selectedTab === '/fieldactivities'}
									onClick={trackFieldActivities}
									disabled={!areGrowersAvailable}
								>
									{headerRoutes['/fieldactivities']}
								</TabButton>
							</Link>
						</Tooltip>
						<Dropdown menu={{ items: dataHubMenu, style: { color: theme.colors.darkGrey, padding: 0 } }} trigger={['click']} placement='top'>
							<TabButton
								className='HeaderButtonDataHub'
								width='15%'
								style={{ maxWidth: '200px', margin: 0, height: '100%' }}
								selected={selectedTab === '/datahub'}
								onClick={trackDataHub}
							>
								{headerRoutes['/datahub']}
							</TabButton>
						</Dropdown>
						<Dropdown
							menu={{
								items: settingsMenuItems,
								style:{ color: theme.colors.darkGrey },
								className: 'HeaderProfileManagedAccount'
							}}
							trigger={['click']}
						>
							<Badge dot={IsRecording}>
								<IconButton
									className='HeaderButtonProfile'
									variant='profile'
									style={{ marginLeft: 10, transform: 'translate(0px, -14px)', borderRadius: 16, backgroundColor: IsManagingAccount && theme.colors.secondary }}
								/>
							</Badge>
						</Dropdown>
					</div>
				</section>
			</header>
			<StyledModal
				open={attributesModalVisible}
				destroyOnClose={true}
				onCancel={() => setAttributesModalVisible(false)}
				width={'90%'}
				height={'85vh'}
				bodyStyle={{ overflow: 'hidden' }}
				isLoading={IsLoadingUserAttributes || IsSavingUserAttributes}
				loadingText={IsLoadingUserAttributes ? 'Loading' : IsSavingUserAttributes ? 'Saving' : ''}
			>
				<UserAttributes />
			</StyledModal>
			<StyledModal
				open={settingsModalVisible}
				onCancel={() => setSettingsModalVisible(false)}
			>
				<UserSettingsContent />
			</StyledModal>
			<StyledModal
				open={customTreatmentModalVisible}
				flexBody={true}
				loadingText={'Loading'}
				destroyOnClose={true}
				onCancel={() => setCustomTreatmentModalVisible(false)}
				isLoading={IsLoadingGetTreatments || IsLoadingSaveTreatments}
				width={'50%'}
				height={'75vh'}
			>
				<SoybeanTreatments />
			</StyledModal>
			<StyledModal
				open={ppgModalVisible}
				flexBody={true}
				loadingText={'Loading'}
				destroyOnClose={true}
				onCancel={() => setPpgModalVisible(false)}
				isLoading={IsLoadingGetTreatments || IsLoadingSaveTreatments}
				width={'55%'}
				height={'65vh'}
			>
				<PpgMain />
			</StyledModal>
		</>
	);
};

const mapStateToProps = (state: RootState) => ({
	CanRecord: hasEffectivePermission(state, UserPermission.CanRecord, true),
	CanSeeTroubleShootingTools: hasEffectivePermission(state, UserPermission.CanSeeTroubleShootingTools, true),
	CanSeeUserAdmin: hasEffectivePermission(state, UserPermission.UserAdministrator, true),
	Growers: state.grower.Growers,
	HasGhxCoreSSO: hasEffectivePermission(state, UserPermission.GhxCoreSSO, true),
	IsLoadingGetTreatments: state.customTreatments.isLoadingGetTreatments,
	IsLoadingGrowers: state.grower.isLoadingGrowers,
	IsLoadingSaveTreatments: state.customTreatments.isLoadingSaveTreatments,
	IsLoadingUserAttributes: state.userAttributes.isLoading,
	IsLoadingUserInfo: state.userInfo.isLoading,
	IsManagingAccount: isManagingAccount(state),
	IsRecording: state.recording.isRecording,
	IsSavingUserAttributes: state.userAttributes.isSaving,
	UserInfo: state.userInfo,
	UserIsGrower: hasEffectivePermission(state, UserPermission.IsGrower),
	UserSeesEnogenTheme: hasEffectivePermission(state, UserPermission.CanSeeEnogenTheme),
	SelectedAccount: getCurrentActingUser(state),
	FeatureFlags: featureFlagSelector(state),
	CanSsoLogistics: hasEffectivePermission(state, UserPermission.LogisticsSSO, true),
	UserCanSeePPG: hasEffectivePermission(state, UserPermission.CanSeePPG, true),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
	ChangeInteractionMode: (mode: IInteractionMode) => dispatch(setMapInteractionMode(mode)),
	ClearFileState: makeDispatch(dispatch, clearFileState),
	ClearRecordingState: () => dispatch(clearState()),
	ClearSelectedGrowerId: () => dispatch(clearSelectedGrowerIdState()),
	DeleteDrawn: () => dispatch(deleteDrawnFeatures()),
	DisplayDrawingTools: (display: boolean) => dispatch(toggleDrawingTools(display)),
	LaunchProductAnalyzer: () => dispatch(getProductAnalyzerLaunchUrl()),
	LaunchGhxCoreSso: makeDispatch(dispatch, redirectToGhxCoreSso),
	SetShowMapSearch: ((value: boolean) => dispatch(setShowMapSearch(value))),
	StartRecording: (cardId: string) => dispatch(startLogrocketRecording(cardId)),
	RedirectToGhxLogistics: makeDispatch(dispatch, redirectToGhxLogisticsSso),
	ClearSelectedDashboardTab: makeDispatch(dispatch, clearSelectedDashboardTab),
	ClearSelectedDashboardYear: makeDispatch(dispatch, clearSelectedYearState),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export const Header = connector(HeaderComponent);